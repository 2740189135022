import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col, Accordion } from 'react-bootstrap';

import Layout from '../components/layout/layout';
// import Seo from '../components/seo/seo';
import InsuranceProviders from '../components/insuranceProviders/insuranceProviders';
import FreedomBanner from '../components/freedomBanner/freedomBanner';
import Seo from '../components/seo/seo';

const InsurancePage = () => {

  return (
    <Layout>
      <Seo
        canonical={`https://www.essentialbehavioral.com/insurance/`}
      />

      <>
        <header className={`pt-6 pb-5 text-header`}>
          <Container style={{ zIndex: 1, position: 'relative' }}>
            <Row className={`justify-content-center text-center`}>
              <Col xs={11} md={8}>
                <h1>Insurance</h1>
                <pre>All your recovery financing questions answered.</pre>
                <a
                  href={`tel:(866) 209-6600`}
                  className={`btn btn-success btn-lg btn-call mt-3`}
                >
                  Call (866) 209-6600
                </a>
              </Col>
            </Row>
          </Container>
        </header>

        <hr />

        <section className={`py-5`}>
          <InsuranceProviders />

          <Container>
            <Row className={`justify-content-center text-center mt-4`}>
              <Col xs={11} md={8}>
                <p>We are committed to helping clients gain access to the care they need. Our intake coordinators will help you understand your insurance policy and guide you toward financing resources, such as aid or loans, if you need assistance.</p>
                <p>Financing addiction or mental health treatment may feel overwhelming at first, but it can be helpful to put the costs in perspective. If your substance use or mental health disorder costs you $100 a week, you will spend more than $20,000 over four years, not including the cost of related health care problems, hospital visits or lost income. The long-term costs of behavioral health disorders often far outweigh the cost of recovery.</p>
                <p>Thankfully, recent changes to health insurance laws have made coverage for mental health disorders and related treatment for drug and alcohol addiction more accessible and affordable. Contact us to verify your insurance and learn about other available funding resources.</p>
              </Col>
            </Row>
          </Container>
        </section>

        <hr />

        <section className={`py-5 text-center`}>
          <Container>
            <Row className="justify-content-center section-content mb-4">
              <Col xs={11} md={8}>
                <h2>Frequently Asked Questions</h2>
                <p>We work directly with clients to make treatment accessible and affordable. Most clients are able to cover their treatment through their insurance, one of our flexible payment plans or a combination of both. Here are answers to common insurance questions.</p>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col xs={11} md={8}>
                <Accordion>
                  <div
                    className={`pt-3 pb-2`}
                    style={{ borderTop: `1px solid rgba(0, 0, 0, 0.1)` }}
                    key={0}
                  >
                    <Accordion.Toggle
                      as={`h3`}
                      eventKey={`0`}
                      className={`text-primary`}
                    >
                      Do you accept workers' compensation insurance?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`0`}>
                      <div className={`accordion-content`}>
                        <p>We sure do. If you became addicted to prescription medication because of a work-related injury, your workers’ compensation insurance will likely cover your detox treatment.</p>
                      </div>
                    </Accordion.Collapse>
                  </div>
                  <div
                    className={`pt-3 pb-2`}
                    style={{ borderTop: `1px solid rgba(0, 0, 0, 0.1)` }}
                    key={1}
                  >
                    <Accordion.Toggle
                      as={`h3`}
                      eventKey={`1`}
                      className={`text-primary`}
                    >
                      What if I don't have insurance?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`1`}>
                      <div className={`accordion-content`}>
                        <p>We work with many clients who do not have insurance, or whose insurance does not cover detox. For these clients, we offer flexible payment programs that can make treatment affordable even without insurance.</p>
                      </div>
                    </Accordion.Collapse>
                  </div>
                  <div
                    className={`pt-3 pb-2`}
                    style={{ borderTop: `1px solid rgba(0, 0, 0, 0.1)` }}
                    key={2}
                  >
                    <Accordion.Toggle
                      as={`h3`}
                      eventKey={`2`}
                      className={`text-primary`}
                    >
                      What if I've been in treatment before?
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`2`}>
                      <div className={`accordion-content`}>
                        <p>Although a prior experience with detox or rehabilitation program may affect how your eligibility for coverage, we can work with you through our payment programs to get the treatment you need now.</p>
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              </Col>
            </Row>
          </Container>
        </section>

        <FreedomBanner />
      </>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpPage(databaseId: { eq: 484 }) {
      title
      seo {
        metaTitle
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      pageInsurance {
        insuranceHeader {
          content
        }
        insuranceContent
        insuranceFaq {
          content
          accordionLayout {
            groups {
              headline
              content
            }
          }
        }
      }
    }
    wp {
      globalContent {
        globalButtons {
          buttonSettings {
            buttonText
            linkType
            internalLink {
              ... on WpPost {
                id
                uri
              }
              ... on WpPage {
                id
                uri
              }
            }
            externalLink
            htmlClass
          }
        }
        globalContact {
          phone
        }
      }
    }
  }
`;

export default InsurancePage;
