import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import Img from 'gatsby-image';

export default function InsuranceProviders() {
  return (
    <StaticQuery
      query={graphql`
        query InsuranceProvidersQuery {
          wp {
            globalContent {
              insuranceProviders {
                insurersHeadline
                insurers {
                  logo {
                    localFile {
                      ...imageFluidSmall
                    }
                    altText
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Container className={`text-center`}>
          <h2 className={`mb-4`}>
            We Accept These Insurers And Many More
          </h2>

          <Row className={`justify-content-center`}>
            {data.wp.globalContent.insuranceProviders.insurers.map(
              (insurer, index) => (
                <Col xs={4} md={2} key={index}>
                  <Img
                    fluid={insurer.logo.localFile.childImageSharp.fluid}
                    alt={insurer.logo.altText}
                  />
                </Col>
              )
            )}
          </Row>
        </Container>
      )}
    />
  );
}
