import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Col, Container, Row } from 'react-bootstrap';
import * as Globals from '../../../globals';

export default function FreedomBanner() {
  const data = useStaticQuery(graphql`
    query FreedomBannerQuery {
      wp {
        globalContent {
          banners {
            heroBanner {
              content
              backgroundType
              backgroundImage {
                localFile {
                  ...imageFluidLarge
                }
              }
            }
          }
          globalContact {
            phone
          }
        }
      }
    }
  `);

  let backgroundFluidImageStack = [
    data.wp.globalContent.banners.heroBanner.backgroundImage.localFile
      .childImageSharp.fluid,
    Globals.backgroundOverlay,
  ].reverse();

  return (
    <BackgroundImage
      fluid={backgroundFluidImageStack}
      className="section-background jumbotron banner-freedom"
    >
      <Container
        className={`py-6 text-center`}
        style={{ zIndex: 1, position: 'relative' }}
      >
        <Row className={`justify-content-center`}>
          <Col xs={11} md={10} className={`text-white`}>
            <h2>Your path toward long-term recovery starts here.</h2>
            <pre>Professional support for addiction treatment and co-occurring mental health disorders.</pre>

            <a
              href={`tel:(866) 209-6600`}
              className={`btn btn-success btn-lg btn-call mt-3`}
            >
              Call (866) 209-6600
            </a>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
}
